import React, { Dispatch, SetStateAction, useState } from "react";
import { Divider, List, ListItem, Image, Stack, useViewport } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import ListType from "@bookingcom/bui-react/components/List/List.types";
import { AutocompleteResultInterface } from "../../server/services/taxi-autocomplete";
import { useKeyPress } from "../../hooks/useKeyPress";
import { TaxiAutocompleteSearchDropDownItem } from "./TaxiAutocompleteSearchDropDownItem";
import styles from "./TaxiAutocompleteSearchDropDown.module.css";
import { TEST_IDS } from "./TaxiAutocompleteSearchDropDown.constants";

export const LIST_BOX_ID = "taxi-auto-complete-list-box";

const EVENT_KEY = {
	DOWN: "ArrowDown",
	ENTER: "Enter",
	ESCAPE: "Escape",
	TAB: "Tab",
	UP: "ArrowUp",
};

const NO_FOCUS_RESULT = -1;

enum Direction {
	UP = -1,
	DOWN = 1,
}

interface LocationResultsProps extends React.Attributes {
	name: string;
	onFocus?: (focusedResultId: string | null) => void;
	onSelect: (result: AutocompleteResultInterface, tab?: boolean) => void;
	results: Array<AutocompleteResultInterface>;
	showPoweredByGoogleItem?: boolean;
	showResults: boolean;
}

export const TaxiAutocompleteSearchDropDown: React.FC<LocationResultsProps> = ({
	results,
	onSelect,
	name,
	onFocus,
	showResults,
	showPoweredByGoogleItem,
}: LocationResultsProps): JSX.Element => {
	const { translate: t } = useTranslations();
	const { isLarge } = useViewport();
	const [focusedResult, setFocusedResult]: [number, Dispatch<SetStateAction<number>>] = useState(NO_FOCUS_RESULT);

	const setFocus = (direction: Direction): void => {
		if (!showResults) {
			return;
		}

		let newResult: number;

		if (direction === Direction.UP && focusedResult > 0) {
			newResult = focusedResult + direction;
			setFocusedResult(focusedResult + direction);
		}

		if (direction === Direction.DOWN) {
			if (focusedResult === results.length - 1) {
				newResult = 0;
				setFocusedResult(newResult);
			} else {
				newResult = focusedResult + direction;
				setFocusedResult(newResult);
			}
		}

		if (!onFocus) {
			return;
		}

		if (newResult === NO_FOCUS_RESULT) {
			return onFocus(null);
		}

		onFocus(`${LIST_BOX_ID}__listbox-item--${newResult}`);
	};

	const clearFocus = (): void => {
		if (onFocus) {
			onFocus("");
		}
	};

	const clearFocusedResult = (): void => {
		setFocusedResult(NO_FOCUS_RESULT);
	};

	const selectFocusedResult = (): void => {
		clearFocus();

		if (!showResults) {
			return;
		}

		if (focusedResult === NO_FOCUS_RESULT) {
			return onSelect(results[0]);
		}

		onSelect(results[focusedResult]);
		clearFocusedResult();
	};

	const onDropDownItemSelect = (result: AutocompleteResultInterface): void => {
		clearFocus();
		onSelect(result);
		clearFocusedResult();
	};

	useKeyPress({
		[EVENT_KEY.UP]: () => setFocus(Direction.UP),
		[EVENT_KEY.DOWN]: () => setFocus(Direction.DOWN),
		[EVENT_KEY.ENTER]: selectFocusedResult,
		[EVENT_KEY.TAB]: selectFocusedResult,
	});

	const hasResults = results.length > 0;
	const hideOptions = !results || results.length === 0 || showResults === false;

	const createAllOptions = (): ListType.Item[] => {
		const LIST_OPTIONS: ListType.Item[] = [];

		if (hideOptions) {
			return LIST_OPTIONS;
		}

		if (results && results.length > 0 && showResults) {
			results.forEach((result, index) => {
				LIST_OPTIONS.push({
					content: (
						<TaxiAutocompleteSearchDropDownItem
							id={`${LIST_BOX_ID}`}
							key={result.id}
							index={index}
							name={name}
							result={result}
							shouldFocus={index === focusedResult}
							onSelect={onDropDownItemSelect}
						/>
					),
				});
			});
		}

		if (showPoweredByGoogleItem) {
			LIST_OPTIONS.push({
				content: (
					<ListItem
						key="list-item-last"
						className={styles["taxi-autocomplete-search-drop-down__google-container"]}
						aria-hidden="true"
					>
						<Divider className={styles["taxi-autocomplete-search-drop-down__divider"]} />
						<Stack justifyContent="end" gap={0.5} alignItems={isLarge ? "start" : "center"}>
							<Image
								className={styles["taxi-autocomplete-search-drop-down__google-image"]}
								height="18px"
								width="144px"
								src="https://assets.taxi.booking.com/3.813.0/img/powered-by-google.png"
								alt={t("gt_mig_rides_web_search_form_search_powered-by-google")}
								imgAttributes={{
									"data-testid": `${TEST_IDS.autocompleteGoogleImagePrefix}-${name}`,
								}}
							/>
						</Stack>
					</ListItem>
				),
			});
		}

		return LIST_OPTIONS;
	};

	return (
		<List
			className={[
				styles["taxi-autocomplete-search-drop-down__items"],
				styles["bui-dropdown-menu"],
				styles["taxi-autocomplete-search-drop-down__container"],
				showResults ? styles["taxi-autocomplete-search-drop-down__container--active"] : "",
				showResults && hasResults ? styles["taxi-autocomplete-search-drop-down__container--results"] : "",
			]
				.join(" ")
				.trim()}
			aria-label={t("gt_mig_rides_web_search_form_route-panner_results")}
			items={createAllOptions()}
			attributes={{
				"id": `taxi-autocomplete-search-drop-down-${name}`,
				"role": "list",
				"data-testid": `${TEST_IDS.autocompleteContainerPrefix}-${name}`,
			}}
		/>
	);
};
