import querystring, { ParsedUrlQueryInput } from "querystring";
import { RidesPubSub } from "@btransport/rides-pubsub-library";
import { getOrchestrator } from "@bookingcom/web-page-orchestrator";
import { getFormattedDate, getFormattedTime } from "../components/TaxiDateTimePicker/dateTimeUtil";
import type {
	ActionInterface,
	SearchFormStateInterface,
	SelectedLocationType,
} from "../contexts/SearchFormContext/types";
import type { LocationTypes, SearchParameters, RateParams, DeepLinkSearchParameters, SubmitFormPayload } from "./types";
import { DEFAULT_LANG } from "../constants/defaults";
import { FORM_TYPES, SEARCH_FORM_PARAMETERS_EVENT } from "../constants/events";
import { getTaxiSearchboxContext } from "../utilities/taxi-search-box-context";

const stateWithReturnDateError = (state: SearchFormStateInterface): SearchFormStateInterface => ({
	...state,
	pickup: {
		...state.pickup,
		hasError: !state.pickup.selected,
	},
	dropoff: {
		...state.dropoff,
		hasError: !state.dropoff.selected,
	},
	hasReturnDateError: true,
});

const generateLatLng = (
	lat: SelectedLocationType["latitude"],
	lng: SelectedLocationType["longitude"],
): string | undefined => (lat && lng ? `${lat},${lng}` : undefined);

const generateLocationType = (selectedLocation: SelectedLocationType): LocationTypes => ({
	iata: selectedLocation.iata,
	latLng: generateLatLng(selectedLocation.latitude, selectedLocation.longitude),
	name: selectedLocation.name,
	placeId: selectedLocation.googlePlaceId,
	type: selectedLocation.type,
});

const generateSingleSearchParameters = (
	state: SearchFormStateInterface,
	bothLocationSelected: boolean,
	lang = DEFAULT_LANG,
): SearchParameters => ({
	...(bothLocationSelected && {
		pickUp: state.pickup.selected && generateLocationType(state.pickup.selected),
		dropOff: state.dropoff.selected && generateLocationType(state.dropoff.selected),
	}),
	date: getFormattedDate(state.outboundDateTime),
	lang,
	passengers: state.passengers,
	time: getFormattedTime(state.outboundDateTime),
	...(state.selectedAirport && {
		freeTaxi: {
			pickUp: state.selectedAirport.iata,
			establishment: state.selectedAirport.establishment,
		},
	}),
	...(state.preSelectedResultReference ? { preSelectedResultReference: state.preSelectedResultReference } : {}),
});

const generateReturnSearchParameters = (
	state: SearchFormStateInterface,
	bothLocationSelected: boolean,
	lang = DEFAULT_LANG,
	returnBannerClicked?: boolean,
): SearchParameters =>
	state.isReturn
		? Object.assign(generateSingleSearchParameters(state, bothLocationSelected, lang), {
				returnDate: getFormattedDate(state.returnDateTime),
				returnJourney: state.isReturn,
				returnTime: getFormattedTime(state.returnDateTime),
				...(returnBannerClicked ? { returnBannerClicked } : {}),
			})
		: generateSingleSearchParameters(state, bothLocationSelected, lang);

const redirectToDeepLink = (deepLinkBaseUrl: string, searchParams: SearchParameters): void => {
	let deepLinkParams: DeepLinkSearchParameters = {
		date: searchParams.date,
		dropoff: searchParams.dropOff?.placeId,
		dropoffType: searchParams.dropOff?.type,
		lang: searchParams.lang,
		passengers: searchParams.passengers,
		pickup: searchParams.pickUp?.placeId,
		pickupType: searchParams.pickUp?.type,
		time: searchParams.time,
	};

	if (searchParams.returnJourney) {
		deepLinkParams = Object.assign(deepLinkParams, {
			returnDate: searchParams.returnDate,
			returnTime: searchParams.returnTime,
			returnJourney: searchParams.returnJourney,
			...(searchParams.returnBannerClicked ? { returnBannerClicked: searchParams.returnBannerClicked } : {}),
		});
	}

	if (searchParams.preSelectedResultReference) {
		deepLinkParams = {
			...deepLinkParams,
			preSelectedResultReference: searchParams.preSelectedResultReference,
		};
	}

	const context = getTaxiSearchboxContext();
	if (context?.aid && context.aid.trim() !== "") {
		deepLinkParams.aid = context.aid;
	}

	if (context?.label && context.label.trim() !== "") {
		deepLinkParams.label = context.label;
	}

	if (context?.adplat && context.adplat.trim() !== "") {
		deepLinkParams.adplat = context.adplat;
	}

	const queryParameters = querystring.stringify(deepLinkParams as unknown as ParsedUrlQueryInput);
	window.location.assign(`${deepLinkBaseUrl}/search?${queryParameters}`);
};

const publishSearchParams = async (searchParams: SearchParameters): Promise<void> => {
	const orchestrator = getOrchestrator();

	if (!orchestrator) {
		RidesPubSub.publish(SEARCH_FORM_PARAMETERS_EVENT, searchParams);
		return;
	}

	const rateParamsQuery = await orchestrator.runQuery("RATE_PARAMS");
	const flightInfoQuery = await orchestrator.runQuery("FLIGHT_INFO");

	let pickupDatetime = `${searchParams.date}T${searchParams.time}`;
	let isMatchingPlaceId = false;
	let isMatchingFlightDate = false;

	if (flightInfoQuery) {
		isMatchingPlaceId = searchParams.pickUp?.placeId === flightInfoQuery?.pickupPlaceId;
		isMatchingFlightDate = searchParams.date === flightInfoQuery?.flightArrivalDateTime?.split("T")[0];
	}

	if (isMatchingPlaceId && isMatchingFlightDate) {
		pickupDatetime = `${searchParams.date}T${flightInfoQuery.flightArrivalTime}`;
	}

	const rateParams: RateParams = {
		dropoff: searchParams.dropOff?.placeId,
		dropoffEstablishment: searchParams.dropOff?.name,
		dropoffType: searchParams.dropOff?.type,
		passenger: searchParams.passengers,
		pickup: searchParams.pickUp?.placeId,
		pickupDateTime: pickupDatetime,
		pickupEstablishment: searchParams.pickUp?.name,
		pickupIata: searchParams.pickUp?.iata,
		pickupType: searchParams.pickUp?.type,
		shouldTriggerPageRefresh: true,
	};

	if (searchParams.returnBannerClicked) {
		rateParams.returnBannerClicked = true;
	}

	if (rateParamsQuery?.language) {
		rateParams.language = rateParamsQuery.language;
	}

	if (rateParamsQuery?.currency) {
		rateParams.currency = rateParamsQuery.currency;
	}

	if (rateParamsQuery?.affiliate) {
		rateParams.affiliate = rateParamsQuery.affiliate;
	}

	if (rateParamsQuery?.enablePTSearch) {
		rateParams.enablePTSearch = rateParamsQuery.enablePTSearch;
	}

	if (searchParams.returnJourney) {
		rateParams.returnJourney = `${searchParams.returnJourney}`;
		rateParams.returnDateTime = `${searchParams.returnDate}T${searchParams.returnTime}`;
	}

	if (searchParams?.freeTaxi?.establishment && searchParams?.freeTaxi?.pickUp) {
		rateParams.pickup = searchParams.freeTaxi.pickUp;
		rateParams.pickupEstablishment = searchParams.freeTaxi.establishment;
	}

	if (searchParams.preSelectedResultReference) {
		rateParams.preSelectedResultReference = searchParams.preSelectedResultReference;
	}

	if (flightInfoQuery?.flightNumber && isMatchingPlaceId) {
		rateParams.flightNumber = flightInfoQuery?.flightNumber;
	}

	orchestrator.runCommand("UPDATE_RATE_PARAMS", rateParams);
};

const submitFormSingleSearchType = (
	state: SearchFormStateInterface,
	deepLinkBaseUrl: string,
	lang = DEFAULT_LANG,
): SearchFormStateInterface => {
	const bothLocationSelected = !!state.pickup.selected && !!state.dropoff.selected;
	const isDateValid = state.outboundDateTime !== undefined;
	const isDeepLink = !state.usePubSubEvent;

	if (!bothLocationSelected || !isDateValid) {
		return stateWithReturnDateError(state);
	}

	const parameters = generateSingleSearchParameters(state, bothLocationSelected, lang);

	if (isDeepLink) {
		redirectToDeepLink(deepLinkBaseUrl, parameters);
		return state;
	}

	publishSearchParams(parameters);
	return state;
};

const submitFormReturnSearchType = (
	state: SearchFormStateInterface,
	deepLinkBaseUrl: string,
	lang = DEFAULT_LANG,
	returnBannerClicked?: boolean,
): SearchFormStateInterface => {
	const bothLocationSelected = !!state.pickup.selected && !!state.dropoff.selected;
	const isDateValid = state.isReturn ? state.outboundDateTime < state.returnDateTime : true;
	const isDeepLink = !state.usePubSubEvent;

	if (!bothLocationSelected || !isDateValid) {
		return stateWithReturnDateError(state);
	}

	const parameters = generateReturnSearchParameters(state, bothLocationSelected, lang, returnBannerClicked);

	if (isDeepLink) {
		redirectToDeepLink(deepLinkBaseUrl, parameters);
		return state;
	}

	publishSearchParams(parameters);
	return state;
};

export const submitFormAction = (
	state: SearchFormStateInterface,
	action: ActionInterface,
): SearchFormStateInterface => {
	const baseUrl = typeof action.payload === "string" ? action.payload : (action.payload as SubmitFormPayload).baseUrl;
	const returnBannerClicked =
		typeof action.payload === "string" ? undefined : (action.payload as SubmitFormPayload).returnBannerClicked;

	if (state.type === FORM_TYPES.SINGLE_SEARCH) {
		return submitFormSingleSearchType(state, baseUrl, state?.lang?.toString());
	}

	return submitFormReturnSearchType(state, baseUrl, state?.lang?.toString(), returnBannerClicked);
};
